'use strict';

const sortJobs = (jobList, sortType, secondSortType) =>{
    jobList.sort(function(a, b){
        if (a[sortType] < b[sortType]){
            return -1;
        }
        if(a[sortType] > b[sortType]){
            return 1;
        }
        if(secondSortType){
            if (a[secondSortType] < b[secondSortType]) {
                return -1;
            }
            if (a[secondSortType] > b[secondSortType]) {
                return 1;
            }
        }
        return 0;
    })
    return jobList
}

export const filterJobs = (state) => {
    let filteredJobs = []
    //Category filtered
    if (state.jobCategory && !state.jobLocation && !state.jobByRadius) {
        if (state.jobCategory === 'other') {
            for (let job of state.Jobs) {
                if (!job.JobTitle.toLowerCase().includes('cdl') &&
                    !job.JobTitle.toLowerCase().includes('dispatcher') &&
                    !job.JobTitle.toLowerCase().includes('administrative') &&
                    !job.JobTitle.toLowerCase().includes('mechanic')) {
                    filteredJobs.push(job);
                }
            }
        }
        else {
            for (let job of state.Jobs) {
                if (job.JobTitle.toLowerCase().includes(state.jobCategory)) {
                    filteredJobs.push(job);
                }
            }
        }
        
        return sortJobs(filteredJobs, 'StateAbbr', 'City');
    }

    //Category AND State filtered
    else if (state.jobCategory && state.jobLocation) {
        if (state.jobCategory === 'other') {
            for (let job of state.Jobs) {
                if (!job.JobTitle.toLowerCase().includes('cdl') &&
                    !job.JobTitle.toLowerCase().includes('dispatcher') &&
                    !job.JobTitle.toLowerCase().includes('administrative') &&
                    !job.JobTitle.toLowerCase().includes('mechanic') &&
                    job.StateName.toLowerCase() === state.jobLocation.toLowerCase()) {
                    filteredJobs.push(job);
                }
            }
        }
        else {
            for (let job of state.Jobs) {
                if ((job.JobTitle.toLowerCase().includes(state.jobCategory)) &&
                    (job.StateName.toLowerCase() === state.jobLocation.toLowerCase())) {
                    filteredJobs.push(job);
                }
            }
        }
        return sortJobs(filteredJobs, 'StateAbbr', 'City');
    }
    //State filtered
    else if (state.jobLocation && !state.jobCategory) {
        for (let job of state.Jobs) {
            if ((job.StateName.toLowerCase().replace(' ','')) === state.jobLocation.toLowerCase().replace(' ','')) {
                filteredJobs.push(job);
            }
        }
        return sortJobs(filteredJobs, 'City');
    }
    //Radius Filtered
    else if(state.jobByRadius && !state.jobCategory){

        state.jobByRadius = sortJobs(state.jobByRadius, 'Distance',null);
        for(let jobInRange of state.jobByRadius){
            for(let job of state.Jobs){
                if(job.JobID === jobInRange.jobID){
                    filteredJobs.push(job);
                    break;
                }
            }
        }
        return filteredJobs;
    }
    //Radius and Category Filtered
    else if(state.jobByRadius && state.jobCategory){

        state.jobByRadius = sortJobs(state.jobByRadius, 'Distance', null);
        if (state.jobCategory === 'other') {
            for(let jobInRange of state.jobByRadius){
                for (let job of state.Jobs) {
                    if (job.JobID === jobInRange.jobID &&
                        !job.JobTitle.toLowerCase().includes('cdl') &&
                        !job.JobTitle.toLowerCase().includes('dispatcher') &&
                        !job.JobTitle.toLowerCase().includes('administrative') &&
                        !job.JobTitle.toLowerCase().includes('mechanic')) {
                        filteredJobs.push(job);
                        break;
                    }
                }
            }
        }
        else {
            for(let jobInRange of state.jobByRadius){
                for (let job of state.Jobs) {
                    if (job.JobID === jobInRange.jobID &&
                        job.JobTitle.toLowerCase().includes(state.jobCategory)) {
                        filteredJobs.push(job);
                        break;
                    }
                }
            }
        }
        return filteredJobs;
    }


    //no filter
    else {
        return sortJobs(state.Jobs, 'StateAbbr', 'City');
    }

}