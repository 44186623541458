'use strict';

import { h, text } from 'hyperapp';
const img = require('../assets/ico/FirstFleetColorLogo.png')

//navbar-inverse values edited to make bar non transparent css is commented out there
export const navBar = () =>{
    return h('div',{}, [
        h('nav', {class:'navbar navbar-fixed-top navbar-inverse', id:'navBar'},[
            h('div', {class:'container'},[
                h('div', {class:'navbar-header'},[
                    h('button', {type:'button', class:'navbar-toggle collapsed', 'data-toggle':'collapse', 'data-target':'#navbar_main', ariaExpanded:'false'}, [
                        h('span', {class:'sr-only'}, text('Toggle navigation')),
                        h('span', {class:'icon-bar'}),
                        h('span', {class:'icon-bar'}),
                        h('span', {class:'icon-bar'})
                    ]),
                    h('img', { src: img, class:'navBarLogo'})
                ]),
                h('div', {class:'collapse navbar-collapse', id:'navbar_main'},[
                    h('ul', {class:'nav navbar-nav navbar-right'},[
                        h('li', {class:'active'}, [
                            h('a', {href:'#section_banner'}, text('Welcome'))
                        ]),
                        h('li',{}, [
                            h('a', {href:'#section_jobs', id:'jobsSection'}, text('Careers'))
                        ]),
                        h('li', {}, [
                            h('a', { href: '#section_jobInfo', id: 'jobInfoSection' }, text('Job Description'))
                        ]),
                        h('li', {class: 'hidden'}, [
                            h('a', { href: '#section_podcasts', id: 'podcastsNav' }, text(''))
                        ]),
                        h('li', {}, [
                            h('a', { href: '#section_aboutUs' }, text('About'))
                        ]),
                        h('li', {}, [
                            h('a', { href: '#section_heritage' }, text('Heritage'))
                        ]),
                        h('li', {}, [
                            h('a', { href: '#section_responsible' }, text('Responsible'))
                        ]),
                        h('li', {}, [
                            h('a', { href: '#section_values' }, text('Values'))
                        ]),
                        h('li', {}, [
                            h('a', { href: '#section_benefits'}, text('Benefits'))
                        ]),
                        h('li', {}, [
                            h('a', { href: '#section_requirements' }, text('Requirements'))
                        ]),
                        h('li', {}, [
                            h('a', { href: '#section_questions' }, text('Questions'))
                        ]),
                    ])
                ])
            ])
        ])
    ])
}    
