'use strict';


export const getJobsSuccess = (state, result) => {
    state.Jobs = result;
    //state.jobSelected = result[0];
    return {...state}
}

export const getJobsError = (state, error) => {
    return {...state};
}

export const getCitySuccess = (state, result) => {
    state.cities = result;
    return {...state};
}

export const getCityError = (state, error) => {
    return {...state};
}

export const getLocationsSuccess = (state, result) => {
    state.jobByRadius = result;
    state.jobLocation = null ;
    document.getElementById('jobsSection').click();
    //correct UI values----
    document.getElementById('locationJobSection').selectedIndex = 0;
    document.getElementById('location').value = '';
    document.getElementById('locationMobile').value = '';
    return {...state};
}

export const clearZipSearch = (state) => {
    state.jobByRadius = null;
    state.jobLocation = null;
    return {...state};
}

export const getLocationsError = (state, error) => {
    console.log(error);
    return {...state};
}

export const setJobsTablePage = (state, page) => {
    if(page === 'prev'){
        state.tablePage -= 1;
    }
    else if(page === 'next'){
        state.tablePage += 1;
    }
    else{
        state.tablePage = page;
    }
    return {...state};
}

export const setCategory = (state, category) =>{
    state.jobCategory = category;
    state.tablePage = 0;
    document.getElementById('jobsSection').click();
    return {...state}
}

export const setLocation = (state, location) =>{
    if(location === null){
        state.jobLocation = null;
        state.tablePage = 0;
        return {...state}
    }
    state.jobLocation = location.replace(' ','');
    state.jobByRadius = null;
    state.tablePage = 0;
    // correct UI values ------
    document.getElementById('locationMobile').value = location;
    document.getElementById('zipSearchJobSection').value = '';
    document.getElementById('jobLocation').value = '';
    //--------------
    document.getElementById('jobsSection').click();
    return {...state}
}

export const setSelectedJob = (state, job) =>{
    state.jobSelected = job;
    if (document.getElementById('jobLink')){
        let txt = document.getElementById('jobLink');
        txt.text = 'Share';
    }
    return {...state};
}

export const changeSearchType = (state) =>{
    let searchDesktop = document.getElementsByName('searchType');

        for (let search of searchDesktop){
            if(search.checked){
                state.searchType = search.id;
            }
        }
    return {...state};
}

export const changeSearchTypeMobile = (state) => {
    let searchMobile = document.getElementsByName('searchTypeMobile');
        for (let search of searchMobile) {
            if (search.checked) {
                state.searchType = search.id;
            }
        }
    return { ...state };
}




