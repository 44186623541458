'use strict';

import { h, text } from 'hyperapp';

export const responsible = () =>{
    return h('section', { class:'section_pricing' ,id:'section_responsible'}, [
        h('div',{class: 'container'}, [
            h('div', {class: 'row'}, [
                h('div', {class:'col-xs-12'}, [
                    h('h2',{class:'page-header text-center'},[
                        h('span', {}, text('Responsible'))
                    ])
                ])
            ]),
            h('br',{}),
            h('div', {class:'row'}, [
                h('div', {class:'col-md-12'}, [
                    h('div', {class:'col-md-6 col-xs-12'}, [
                        // <iframe class="vimeo-player" src="https://player.vimeo.com/video/902049306?h=dc90ea6131&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="560" height="315" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="FirstFleet Partners with Truckers Against Trafficking"></iframe>,
                        h('div', {class:"video-wrapper"}, [
                            <iframe src="https://player.vimeo.com/video/902049306?h=dc90ea6131&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="FirstFleet Partners with Truckers Against Trafficking"></iframe>,
                        ])
                    ]),
                    h('div', {class:'col-md-6 col-xs-12'}, [
                        h('div', {class:'col-md-12'},[
                            h('p', {}, text('Human Trafficking is a major problem, and it’s a problem that is much closer to home than many realize. FirstFleet is proud to support the mission of Truckers Against Trafficking (TAT), a nonprofit organization that trains members of the truck, bus and energy industries to recognize and report instances of human trafficking.' )),
                            h('a', {href: 'https://truckersagainsttrafficking.org/', target: '_blank'}, text('Learn More'))
                        ]),
                    ])
                ])
            ])
        ])
    ])
}