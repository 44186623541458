'use strict';

import { h, text } from 'hyperapp';

export const aboutUs = (state) =>
    h('section', { class:'section_features section_alt', id:'section_aboutUs'},[
        h('div', {class:'container'}, [
            h('div', {class:'row'},[
                h('div', {class:'col-xs-12'},[
                    h('h2', {class: 'page-header text-center'}, [
                        h('span',{}, text('FirstFleet! ')),
                        text('Where Drivers on the go Choose to Stay')
                    ])
                ])
            ]),
            h('div', {class:'row'}, [
                h('div', {class:'col-sm-4'}, [
                    h('div', { class:'features__item ', dataAnimate:'animate-up'},[
                        h('div', {class:'features__item__icon'},[
                            h('i', { class:'glyphicon glyphicon-home'})
                        ]),
                        h('h3', {class:'features__item__heading'}, text('Time At Home')),
                        h('div', { class: 'features__item__body' }, text('Short haul, dedicated customers with consistent routes - home daily Some longer routes - home weekly'))
                    ])
                ]),
                h('div', { class: 'col-sm-4' }, [
                    h('div', { class: 'features__item ', dataAnimate: 'animate-up' }, [
                        h('div', { class: 'features__item__icon' }, [
                            h('i', { class: 'glyphicon glyphicon-briefcase' })
                        ]),
                        h('h3', { class: 'features__item__heading' }, text('Good Pay/Benefits')),
                        h('div', { class: 'features__item__body' }, text('Make up to $65,000 per year with multiple options for extra income'))
                    ])
                ]),
                h('div', { class: 'col-sm-4' }, [
                    h('div', { class: 'features__item ', dataAnimate: 'animate-up' }, [
                        h('div', { class: 'features__item__icon' }, [
                            h('i', { class: 'glyphicon glyphicon-signal' })
                        ]),
                        h('h3', { class: 'features__item__heading' }, text('Advancement')),
                        h('div', { class: 'features__item__body' }, text('FirstFleet keeps growing - 3,000+ trucks, many of our terminal managers and safety managers are former drivers'))
                    ])
                ])
            ]),
            h('div', { class: 'row' }, [
                h('div', { class: 'col-sm-4' }, [
                    h('div', { class: 'features__item ', dataAnimate: 'animate-up' }, [
                        h('div', { class: 'features__item__icon' }, [
                            h('i', { class: 'glyphicon glyphicon-ok' })
                        ]),
                        h('h3', { class: 'features__item__heading' }, text('Safety')),
                        h('div', { class: 'features__item__body' }, text('New trucks/ Industry leading CSA scores'))
                    ])
                ]),
                h('div', { class: 'col-sm-4'}, [
                    h('div', { class: 'features__item ', dataAnimate: 'animate-up' }, [
                        h('div', { class: 'features__item__icon' }, [
                            h('i', { class: 'glyphicon glyphicon-phone' })
                        ]),
                        h('h3', { class: 'features__item__heading' }, text('AT YOUR FINGERTIPS')),
                        h('p', {}, [
                            text('Recruiting Hotline tel: '),
                            h('a', { href: 'tel:888-838-7646' }, text('888-838-7646'))
                        ]),
                        h('p', {}, [
                            text('Corporate Headquarters tel: '),
                            h('a', { href: 'tel:615-890-9229' }, text('615-890-9229'))
                        ])

                    ])
                ]),
                h('div', { class: 'col-sm-4' }, [
                    h('div', { class: 'features__item ', dataAnimate: 'animate-up' }, [
                        h('div', { class: 'features__item__icon' }, [
                            h('i', { class: 'glyphicon glyphicon-info-sign' })
                        ]),
                        h('h3', { class: 'features__item__heading' }, text('Contact Us')),
                        h('p', {}, [
                            text('Recruiting: '),
                            h('a', { href: 'mailto:recruiting@firstfleetinc.com' }, text('recruiting@firstfleetinc.com'))
                        ])
                    ])
                ]),
            ])
        ])
    ])
