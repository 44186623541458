'use strict';

import { h, text } from 'hyperapp';

const setCitySearch = (state, city) => {
    document.getElementById('jobCity').value = `${city.cty_name}, ${city.cty_state}`
    document.getElementById('jobCityLocation').value = `${city.cty_name}, ${city.cty_state}`
    state.cities = null;
    state.cityZip = city.cty_zip;
    return {...state}
}

const setCitySearchMobile = (state, city) => {
    document.getElementById('jobCity').value = `${city.cty_name}, ${city.cty_state}`
    document.getElementById('jobCityLocationMobile').value = `${city.cty_name}, ${city.cty_state}`
    state.cities = null;
    state.cityZip = city.cty_zip;
    return { ...state }
}

export const cities = (state) =>{
    if(state.cities && state.cities.length){
        let suggestions = state.cities.map(city => h('li', { class: 'citySuggestion', key: city.cty_zip, onclick: [setCitySearch, city]}, [
            text(`${city.cty_name}, ${city.cty_state}`)
        ]))
        return h('div',{class:'citySuggest'}, suggestions);
    }else {
        return null;
    }
}

export const citiesMobile = (state) => {
    if (state.cities && state.cities.length) {
        let suggestions = state.cities.map(city => h('li', { class: 'citySuggestionMobile', key: city.cty_zip, onclick: [setCitySearchMobile, city] }, [
            text(`${city.cty_name}, ${city.cty_state}`)
        ]))
        return h('div', { class: 'citySuggestMobile' }, suggestions);
    } else {
        return null;
    }
}