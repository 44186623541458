'use strict';

import { h, text } from 'hyperapp';
import { setJobsTablePage,  setSelectedJob } from '../actions'
import { filterJobs } from './jobs/filterJobs'
import { filterJobsDisplay } from './jobs/filterJobsDisplay'

const jobSelected = (state, job) =>{
  setSelectedJob(state, job)
  document.getElementById('jobInfoSection').click();
  return{...state}
}

const parseSearchParam = (state, param) => { //test /?q=jobID=12323&City=FOUNTAIN
  
  let jobID = param.match(/jobID=[0-9]*/g)
  if(!jobID){
    jobID = param.match(/jobID%3D[0-9]*/g)
  }
  let city = param.match(/City=[a-zA-z]*/g)

  if(jobID && jobID.length && city && city.length){
    if(jobID[0].match('=')){
      jobID = jobID[0].split('=')[1]
    }else{
      jobID = jobID[0].split('%3D')[1]
    }
    city = city[0].split('=')[1]
    for (let job of state.Jobs) {
      if (job.City && job.City.toLowerCase() === city.toLowerCase() && job.TenStreetJobID === parseInt(jobID)) {
        setSelectedJob(state, job)
        document.getElementById('jobInfoSection').click()
      }
    }
  }
  state.firstLoad = false;
  return{...state}
}

export const careers = (state) =>{
  var pages = h('ul', {class:'pagination pagination-sm'},[])
  var jobTable = [];
  if(state.Jobs){
    if (location.search && state.firstLoad){ //if there is a search url param
      parseSearchParam(state, location.search);
    }
    //filterTable
    let filteredJobs = filterJobs(state);

    //create table elements
    let jobs = filteredJobs.map(job => h('tr', { class:'jobSelection', key: job.JobID, onclick:[jobSelected, job]}, [
      h('td', {}, text(job.JobTitle)),
      h('td', {}, text(job.City +', ' + job.StateAbbr))
    ]))
    //group table displays by 10
    for(let x = 0; x<jobs.length; x+=10){
      let jobGroup = jobs.slice(x, x+10);
      jobTable.push(jobGroup);
    }
    //pagination buttons
    if (state.tablePage > 3) {
      pages.children.push(h('li', {}, [
          h('a', { onclick: [setJobsTablePage, 0], class: 'rounded' }, text('First'))
        ])
      )
    }
    if (state.tablePage !== 0){
      pages.children.push(h('li', {}, [
        h('a', { onclick: [setJobsTablePage, 'prev'], class: 'rounded' }, text('Prev'))
      ]))
    }
    for(let x = state.tablePage; x<state.tablePage+5 && x<jobTable.length ; x++){
      if(state.tablePage !== x){
        pages.children.push(
          h('li', {}, [
            h('a', { onclick: [setJobsTablePage, x], class:'rounded' }, text(x + 1)) //set active page
          ])
        )
      }
      else{
        pages.children.push(
          h('li', { class: 'active'}, [
            h('a', { onclick: [setJobsTablePage, x], class:'rounded' }, text(x + 1)) //next pages
          ])
        )
      }
    }

    if (state.tablePage !== jobTable.length - 1){
      pages.children.push(h('li', {}, [
        h('a', { onclick: [setJobsTablePage, 'next'], class: 'rounded' }, text('Next'))
      ]))
    }
  }

  return h('section', { class:'section_services section_alt', id:'section_jobs'}, [
    h('div', {class:'container'}, [
      h('div', {class:'row'}, [
        h('div', {class:'col-xs-12'}, [
          h('h2', {class:'page-header text-center'},[
            text("Careers")
          ]),
          filterJobsDisplay(state),
          h('div', {class:'col-lg-9 pull-right col-xs-12'},[
          h('div', {class:''},[
            h('table', { class:'table table-responsive table-condensed table-hover'}, [
              h('thead', {}, [
                h('tr', {}, [
                  h('th', {}, text('Title')),
                  h('th', {}, text('Location'))
                ]),
              ]),
              h('tbody', {}, 
                (jobTable.length?jobTable[state.tablePage]:text('No Careers match the search')),
              )
            ]),
            pages
          ])
          ])
        ])
      ])
    ])
  ])
}