'use strict';

import { h, text } from 'hyperapp';

export const heritage = () =>{
    return h('section', { class:'section_pricing section_alt' ,id:'section_heritage'}, [
        h('div',{class: 'container'}, [
            h('div', {class: 'row'}, [
                h('div', {class:'col-xs-12'}, [
                    h('h2',{class:'page-header text-center'},[
                        h('span', {}, text('Heritage'))
                    ])
                ])
            ]),
            h('br',{}),
            h('div', {class:'row'}, [
                h('div', {class:'col-md-12'}, [
                    h('div', {class:'col-md-6 col-xs-12'}, [
                        h('div', {class:"video-wrapper"}, [
                            <iframe src="https://www.youtube.com/embed/8Hle-nv5ewA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        ])
                    ]),
                    h('div', {class:'col-md-6 col-xs-12'}, [
                        h('div', {class:'col-md-12'},[
                            h('h2', {class:'title'}, text('FOUNDED IN 1986')),
                            h('p', {}, text('In 1986 Gary & Mary Beth Wilson started FirstFleet based on the ideas of delivering industry leading service to our partners and treating people like family. Over thirty five years later the company has grown in every way possible with over 3,200 employees encompassing over 110 cities.'))
                        ]),
                        h('div', {class:'col-md-12'},[
                            h('h2', {class:'titel'}, text('TRUE TO COURSE')),
                            h('p', {}, text('Staying true to course has not always been easy, but we have successfully remained true to the basic ideas we originated with - take care of your customers and your people. In this interview CEO Daniel Piper talks with founder Gary Wilson about the history of FirstFleet and the type of company we want to be known for.'))
                        ])
                    ])
                ])
            ])
        ])
    ])
}