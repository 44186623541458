'use strict';

import { h,text } from 'hyperapp'

export const questions = () =>{

    return h('section', { id: 'section_questions', class:'section_pricing'}, [
        h('div', {class:'container'}, [
            h('div', { class: 'row' }, [
                h('h2', { class: 'text-center page-header highlightBenefit' }, text('Questions'))
            ]),
            h('div', { class:'row filterTablesDesktop'}, [
                h('div', {class:'col-xs-4'}, [
                    h('div', { class: 'pricing__item questionsBoxSize', dataAnimate: 'animate-up' }, [
                        h('h3', { class:'pricing__item__heading highlightBenefit'}, text('Visit Us')),
                        h('p', { class:'highlightBenefit'}, text('Corporate Headquarters')),
                        h('p', {}, text('202 Heritage Park Drive, Murfreesboro, TN 37129'))
                    ])
                ]),
                h('div', { class: 'col-xs-4' }, [
                    h('div', { class: 'pricing__item questionsBoxSize', dataAnimate: 'animate-up' }, [
                        h('h3', { class: 'pricing__item__heading highlightBenefit' }, text('Email Us')),
                        h('div', {class:'row'}, [
                            h('label', {}, text('Recruiting: ')),
                            h('a', { href:'mailto:recruiting@firstfleetinc.com'}, text(' recruiting@firstfleetinc.com')),
                        ]),
                        h('div', { class: 'row' }, [
                            h('label', {}, text('Benefits: ')),
                            h('a', { href:'mailto:benefitsg@firstfleetinc.com'}, text(' benefits@firstfleetinc.com')),
                        ]),
                        
                    ])
                ]),
                h('div', { class: 'col-xs-4' }, [
                    h('div', { class: 'pricing__item questionsBoxSize', dataAnimate: 'animate-up' }, [
                        h('h3', { class: 'pricing__item__heading highlightBenefit' }, text('Call Us')),
                        h('div', { class: 'row' }, [
                            h('label', {}, text('Recruiting Hotline: ')),
                            h('a', { href: 'tel:888-838-7646'}, text(' (888) 838-7646')),
                        ]),
                        h('div', { class: 'row' }, [
                            h('label', {}, text('Corporate Headquarters: ')),
                            h('a', { href: 'tel:615-890-9229'}, text(' (615) 890-9229')),
                        ])
                    ])
                ])
            ]),
            h('div', { class:'filterTablesMobile'}, [
                h('div', { class: 'row' }, [
                    h('div', { class: 'pricing__item questionsBoxSize', dataAnimate: 'animate-up' }, [
                        h('h3', { class: 'pricing__item__heading highlightBenefit' }, text('Visit Us')),
                        h('p', { class: 'highlightBenefit' }, text('Corporate Headquarters')),
                        h('p', {}, text('202 Heritage Park Drive, Murfreesboto, TN 37129'))
                    ])
                ]),
                h('div', { class: 'row' }, [
                    h('div', { class: 'pricing__item questionsBoxSize', dataAnimate: 'animate-up' }, [
                        h('h3', { class: 'pricing__item__heading highlightBenefit' }, text('Email Us')),
                        h('div', { class: 'row' }, [
                            h('label', {}, text('Recruiting: ')),
                            h('a', {}, text(' recruiting@firstfleetinc.com')),
                        ]),
                        h('div', { class: 'row' }, [
                            h('label', {}, text('Benefits: ')),
                            h('a', {}, text(' benefitsg@firstfleetinc.com')),
                        ]),
                        h('div', { class: 'row' }, [
                            h('label', {}, text('Customer Service: ')),
                            h('a', {}, text(' csg@firstfleetinc.com')),
                        ])

                    ])
                ]),
                h('div', { class: 'row' }, [
                    h('div', { class: 'pricing__item questionsBoxSize', dataAnimate: 'animate-up' }, [
                        h('h3', { class: 'pricing__item__heading highlightBenefit' }, text('Call Us')),
                        h('div', { class: 'row' }, [
                            h('label', {}, text('Corporate Headquarters: ')),
                            h('a', {}, text(' (615) 890-9229')),
                        ]),
                        h('div', { class: 'row' }, [
                            h('label', {}, text('Recruiting Hotline: ')),
                            h('a', {}, text(' (888) 838-7646')),
                        ])
                    ])
                ])
            ])
        ])
    ])
}