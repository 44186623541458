'use strict';


export const state = {
    Jobs: null,
    tablePage: 0,
    jobCategory: null,
    jobLocation: null,
    jobSelected: null,
    jobByRadius: null,
    searchType: 'zipCodeSearch',
    firstLoad: true ,
    cities: null,
    cityZip: null
};