'use strict';

import { h, text} from 'hyperapp'

const createLink = (state) =>{
    let link = 'https://careers.firstfleetinc.com/?q=jobID=' + state.jobSelected.TenStreetJobID + '&City=' + state.jobSelected.City;
    let elem = document.createElement('input');
    elem.setAttribute('value', link);
    document.body.appendChild(elem);
    elem.select()
    document.execCommand("copy");
    document.body.removeChild(elem);
    let txt = document.getElementById(`jobLink`)
    txt.text = 'Copied'
    return {...state}
}


export const jobInfo = (state) =>{
    if(state.jobSelected){
        return h('section', { class: 'section_services ', id: 'section_jobInfo' }, [
            h('div', { class: 'container' }, [
                h('div', { class: 'row' }, [
                    h('div', { class: 'col-xs-12' }, [
                        h('h2', { class: 'page-header text-center' }, [
                            text(state.jobSelected.JobTitle + ' in '),
                            h('span', {}, text(state.jobSelected.City + ', ' + state.jobSelected.StateName))
                        ]),
                        h('a', { class: 'text-center', href: '#section_jobs' }, text('Return to Careers'))
                    ])
                ]),
                h('div', {class:'row'}, [
                    h('div', {class:'col-sm-6'}, [
                        h('div', { class:'services__item jobMainBox'}, [
                            h('h3', {class:'services__item__heading page-header text-center'}, text("Job Description")),
                            h('div', {class:'services__item__icon'}, [
                                h('i', {class:''})
                            ]),
                            h('div', { class:'services__item__content formatText'}, text(state.jobSelected.JobDescription))
                        ])
                    ]),
                    h('div', { class: 'col-sm-6' }, [
                        h('div', {class: 'row'}, [
                            h('div', { class:'services__item jobSubBox'}, [
                            h('h3', { class: 'services__item__heading page-header text-center' }, text("Job Requirements")),
                            h('div', { class: 'services__item__icon' }, [
                                h('i', { class: '' })
                            ]),
                            h('div', { class: 'text-center' }, text(state.jobSelected.JobRequirements))
                        ])
                        ]),
                        h('br', {},),
                        h('div', {class: 'row text-center'}, [
                            h('div', {class:'services__item jobSubBox'}, [
                                h('h3', {class:'services__item__heading page-header text-center'}, text('Job Snapshot')),
                                h('div', {class:'text-center'},[
                                    h('p', { class: 'page-subheader text-center' }, [
                                        text('License class: ' + state.jobSelected.license_class.replace('_', ' ')),
                                        h('br', {},),
                                        text('Driver Type: ' + state.jobSelected.driver_type),
                                        h('br', {},),
                                        text('Average weekly miles: ' + state.jobSelected.average_weekly_miles.replace('_', ' - ')),
                                        h('br', {},),
                                        text('Driver Experience: ' + state.jobSelected.driver_experience.replace('_',' '))
                                    ])
                                ]),
                                h('a', { class: 'btn btn-primary rounded', href: state.jobSelected.JobURL, target: '_blank' }, text('Apply Now')),
                                h('a', { class: 'btn rounded', id:`jobLink` ,onclick: [createLink] }, text('Share'))
                            ])
                        ])
                    ])
                ]),
                h('div',{class:'row'},[
                    h('div', {class:'col-xs-12'}, [
                        h('a', { class: 'pull-right', href:'#section_jobs'}, text('Return to Careers'))
                    ])
                ])
            ])
        ])
    }
    else{
        return h('section', { class: 'section_services section_alt hideSection', id:'section_jobInfo'},[
        ])
    }
}