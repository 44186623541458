'use strict';

import { h,text } from 'hyperapp'
import { jobLocationsJobSection, jobLocationMobile } from './jobLocations'
import { jobCategory, jobCategoryMobile } from './jobCategory'
import { setCategory, setLocation, clearZipSearch } from '~/actions'
import { getLocationsByRadius, getCityLikeJob, getCityLikeJobMobile } from '~/effects'
import { cities, citiesMobile } from '../cities'
import { Debounce } from "hyperapp-fx"

const removeFiltersMobile = (state) =>{
    setCategory(state, null);
    setLocation(state, null);
    clearZipSearch(state);
    document.getElementById('categoryMobile').value = '';
    document.getElementById('locationMobile').value = '';
    document.getElementById('zipSearchJobSectionMobile').value = '';
    document.getElementById('location').value = '';
    document.getElementById('jobCityLocationMobile').value='';
    document.getElementById('jobCity').value='';
    clearBannerSearch(state);
    return {...state}
}

const typingCity = (state) => [
    state,
    Debounce({
        wait: 500,
        action: getCityLikeJob
    })
]

const typingCityMobile = (state) => [
    state,
    Debounce({
        wait: 500,
        action: getCityLikeJobMobile
    })
]

const cityClick = (state) => {
    if(document.getElementById('citySearch')){
        document.getElementById('citySearch').click()
    }else{
        document.getElementById('citySearchMobile').click()
    }
    return state;
}

const clearLocation = (state) =>{
    document.getElementById('location').value = '';
    document.getElementById('locationJobSection').value = '';
    setLocation(state, null)
    return {...state}
}

const clearZip = (state) =>{
    document.getElementById('location').value = '';
    document.getElementById('jobLocation').value = '';
    document.getElementById('zipSearchJobSection'). value = '';
    clearZipSearch(state)
    return{...state}
}

const locationSelected = () =>{
    let selector = document.getElementById('location')
    if (selector && selector.value){
        return h('p', { class: 'jobSelection pull-right glyphicon glyphicon-remove', onclick: [clearLocation] })        
    }
    return null
}
const zipSelected = () =>{
    if (document.getElementById('jobLocation') && document.getElementById('zipSearchJobSection')){
        if (document.getElementById('jobLocation').value){
            return h('p', { class: 'jobSelection pull-right glyphicon glyphicon-remove', onclick: [clearZip] })
        }
        else if (document.getElementById('zipSearchJobSection').value){
            return h('p', { class: 'jobSelection pull-right glyphicon glyphicon-remove', onclick: [clearZip] })
        }
    }
}

const clearBannerSearch = (state) =>{
    document.getElementById('jobType').value = '';
    document.getElementById('jobLocation').value = '';
    return{...state}
}

export const filterJobsDisplay = (state) =>{
    
    return h('div', {}, [
        h('div', { class: 'filterTablesDesktop col-lg-2 col-xs-6' }, [
            h('div', { class: 'row' }, [
                h('table', { class: 'table table-responsive table-hover table-condensed' }, [
                    h('thead', {}, [
                        h('tr', {}, [
                            h('th', {}, text('Category')),
                            (state.jobCategory ? h('th', { class: 'jobSelection glyphicon glyphicon-remove', onclick: [setCategory, null] }) : null)
                        ]),
                    ]),
                    jobCategory(state)
                ])
            ]),
            h('div', { class: 'row form-group' },[
                h('label', {}, text('Location')),
                locationSelected(),
                jobLocationsJobSection(state)
            ]),
            h('div', {class: 'row form-group' },[
                h('label', {}, text('Zip Code')),
                zipSelected(),
                h('input', {class:'form-control input-sm', id:'zipSearchJobSection'},),
                h('br',{}),
                h('label', {}, text('City')),
                h('input', { placeholder: 'Murfreesboro, TN', type: 'text', id: 'jobCityLocation', autocomplete: 'off', oninput: [typingCity, state], onclick: [cityClick, state]}),
                cities(state),
                h('br',{}),
                h('button', { class: 'rounded btn-primary', onclick: [getLocationsByRadius, document.getElementById('zipSearchJobSection')] }, text('search'))
            ])
        ]),
        h('div', { class:' filterTablesMobile'}, [
            h('div', {class:'panel'}, [
                h('div', { class: 'panel-heading', onclick: [clearBannerSearch,state ]}, [
                    h('h3', { class: 'panel-title','data-toggle': 'collapse', href: '#collapseFilter'}, [
                        text('Filter Jobs'),
                        h('a', { class: 'pull-right glyphicon glyphicon-chevron-down',})
                    ])
                ]),
                h('div', { id:'collapseFilter', class:'panel-collapse collapse ' }, [
                    h('div', {class:'panel-body'}, [
                        h('div', { class: 'col-xs-12 form-inline' }, [
                            h('div', { class: 'row' }, [
                                h('label', { class: 'col-xs-4' }, text('Category')),
                                h('div', { class: 'col-xs-8  form-group' }, [
                                    jobCategoryMobile(state)
                                ])
                            ]),
                            h('div', { class: 'row' }, [
                                h('label', { class: 'col-xs-4' }, text('Location')),
                                h('div', { class: 'col-xs-8 form-group' }, [
                                    jobLocationMobile(state)
                                ])
                            ]),
                            h('div', { class: 'row' }, [
                                h('label', {class: 'col-xs-4'}, text('Zip Code')),
                                h('div', {class:'col-xs-8 form-group'},[
                                    h('input', { placeholder: '5 Digit Zip Code', type: 'text', class: 'searchJobBoxes col-xs-7 form-control input-sm  form-group', id: 'zipSearchJobSectionMobile' },)
                                ]),
                            ]),
                            h('div', {class: 'row'}, [
                                h('label', { class: 'col-xs-4'},text('City')),
                                h('div', { class: 'col-xs-8 form-group'}, [
                                    h('input', { class: 'searchJobBoxes col-xs-7 form-control input-sm  form-group', placeholder: 'Murfreesboro, TN', type: 'text', id: 'jobCityLocationMobile', autocomplete: 'off', oninput: [typingCityMobile , state], onclick: [cityClick, state] }),
                                    citiesMobile(state),
                                ])
                                
                            ]),
                            h('br',{},),
                            h('div', {class:'row'}, [
                                h('div', {class:'col-xs-6'},[
                                    h('button', { class: 'btn btn-sm btn-success pull-right', onclick: [getLocationsByRadius, document.getElementById('zipSearchJobSectionMobile')] }, text('search')),
                                ]),
                                
                                h('div', { class:'col-xs-6 '}, [
                                    h('button', { class: 'btn btn-danger btn-sm pull-right', onclick: [removeFiltersMobile] }, [
                                        text('Clear Filters '),
                                        h('span', { class: 'glyphicon glyphicon-remove' })
                                    ]),
                                ]),
                                
                            ])
                        ])
                    ])
                ])
            ])
        ]),
        h('br', {})
    ])
}
