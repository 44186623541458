'use strict';

import { Http } from "hyperapp-fx"
import { getJobsSuccess, getJobsError, getLocationsSuccess, getLocationsError, setLocation, getCitySuccess, getCityError} from './actions'
import { searchJobs } from './components/jobs/searchJobs'

/**
 * Retrieves available jobs and calls getJobsSuccess to set them in state
 */
export const getJobs = () => {
    const headers = new Headers();
    headers.append('Content-Type','text/plain');
    return Http({
        //dev url: 'http://localhost:8081/careers/getjobs',
        url: 'https://api.firstfleetinc.com/site/careers/getjobs',
        options: {
            method: 'GET',
            headers: headers
        },
        response: 'json',
        action: getJobsSuccess,
        error: getJobsError
    })
};

export const getCityLike = (state) => {
    state.cities = null;
    state.cityZip = null;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let cityInput = document.getElementById('jobCity').value;
    document.getElementById('jobCityLocation').value = document.getElementById('jobCity').value;
    cityInput = cityInput.replace(' ','');
    let cityName = cityInput.split(',')[0];
    let stateAbbr = cityInput.split(',')[1];
    return [{...state},
        Http({
            //url: 'http://localhost:8081/careers/getcity',
            url: 'https://api.firstfleetinc.com/site/careers/getcity',
            options: {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ city: cityName, state: stateAbbr })
            },
            response: 'json',
            action: getCitySuccess,
            error: getCityError
        })
    ]
}

export const getCityLikeJob = (state) => {
    state.cities = null;
    state.cityZip = null;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let cityInput = document.getElementById('jobCityLocation').value;
    document.getElementById('jobCity').value = document.getElementById('jobCityLocation').value;
    cityInput = cityInput.replace(' ', '');
    let cityName = cityInput.split(',')[0];
    let stateAbbr = cityInput.split(',')[1];
    return [{ ...state },
    Http({
        //url: 'http://localhost:8081/careers/getcity',
        url: 'https://api.firstfleetinc.com/site/careers/getcity',
        options: {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ city: cityName, state: stateAbbr })
        },
        response: 'json',
        action: getCitySuccess,
        error: getCityError
    })
    ]
}

export const getCityLikeJobMobile = (state) => {
    state.cities = null;
    state.cityZip = null;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let cityInput = document.getElementById('jobCityLocationMobile').value;
    document.getElementById('jobCity').value = document.getElementById('jobCityLocationMobile').value;
    cityInput = cityInput.replace(' ', '');
    let cityName = cityInput.split(',')[0];
    let stateAbbr = cityInput.split(',')[1];
    return [{ ...state },
    Http({
        //url: 'http://localhost:8081/careers/getcity',
        url: 'https://api.firstfleetinc.com/site/careers/getcity',
        options: {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ city: cityName, state: stateAbbr })
        },
        response: 'json',
        action: getCitySuccess,
        error: getCityError
    })
    ]
}

export const getLocationsByRadius = (state, location) => {
    if (document.getElementById('jobType').value){
        searchJobs(state, document.getElementById('jobType').value);
    }
    //if (state.searchType === 'zipCodeSearch' || state.searchType === 'zipCodeSearchMobile'){
    if (document.getElementById('zipSearchJobSection').value || document.getElementById('jobLocation').value || document.getElementById('zipSearchJobSectionMobile').value) {
        location.value = location.value.replace(/ /g,'');
        if (location.value.length !== 5) {
            location.value = '';
            return {...state}
        }
        //get UI in sync -----
        document.getElementById('zipSearchJobSection').value = location.value;
        document.getElementById('jobLocation').value = location.value;
        document.getElementById('zipSearchJobSectionMobile').value = location.value;
        // -------
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return [{ ...state },
            Http({
                //dev url: 'http://localhost:8081/careers/getlocationsbyradius',
                url:'https://api.firstfleetinc.com/site/careers/getlocationsbyradius',
                options: {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({ 'zipCode': location.value }),
                },
                response: 'json',
                action: getLocationsSuccess,
                error: getLocationsError
            })
        ]
    }
    else if ((document.getElementById('citySearch') || document.getElementById('citySearchMobile'))&& (state.searchType === 'citySearch' || state.searchType ==='citySearchMobile' )&& state.cityZip){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return [{ ...state },
        Http({
            //dev url: 'http://localhost:8081/careers/getlocationsbyradius',
            url: 'https://api.firstfleetinc.com/site/careers/getlocationsbyradius',
            options: {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ 'zipCode': state.cityZip }),
            },
            response: 'json',
            action: getLocationsSuccess,
            error: getLocationsError
        })
        ]

    }
    //search by state
    else{
        if (document.getElementById('location').value.length && document.getElementById('location').value !== undefined){
            location = document.getElementById('location');
            setLocation(state, location.value);
            return { ...state }
        }
        return {...state}
    }
    
};

