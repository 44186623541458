'use strict';

import { h, text } from 'hyperapp';
import { setCategory } from '~/actions'

const setCategoryChange = (state) => {
    let newCategory = document.getElementById('categoryMobile').value;
    setCategory(state, newCategory);
    return {...state}
}

export  const jobCategory = (state) => {
    let category = h('tbody', {}, [])

    category.children.push(h('tr', { class:'jobSelection', onclick: [setCategory, 'cdl'] }, [
        h('td', {}, text('Transportation'))
    ]),
        h('tr', { class: 'jobSelection', onclick: [setCategory, 'dispatcher'] }, [
            h('td', {}, text('Distribution'))
        ]),
        h('tr', { class: 'jobSelection', onclick: [setCategory, 'administrative'] }, [
            h('td', {}, text('Admin'))
        ]),
        h('tr', { class: 'jobSelection', onclick: [setCategory, 'mechanic'] }, [
            h('td', {}, text('Skilled Labor'))
        ]),
        h('tr', { class: 'jobSelection', onclick: [setCategory, 'other'] }, [
            h('td', {}, text('Other'))
        ])
    )

    for (let x = 0; x < category.children.length; x++) {
        switch (category.children[x].children[0].children[0].type) {
            case 'Other':
                if ('other' === state.jobCategory) {
                    category.children[x].children[0].props = { class: 'jobCategoryActive' }
                }
                break;
            case 'Transportation':
                if ('cdl' === state.jobCategory) {
                    category.children[x].children[0].props = { class: 'jobCategoryActive' }
                }
                break;
            case 'Distribution':
                if ('dispatcher' === state.jobCategory) {
                    category.children[x].children[0].props = { class: 'jobCategoryActive' }
                }
                break;
            case 'Admin':
                if ('administrative' === state.jobCategory) {
                    category.children[x].children[0].props = { class: 'jobCategoryActive' }
                }
                break;
            case 'Skilled Labor':
                if ('mechanic' === state.jobCategory) {
                    category.children[x].children[0].props = { class: 'jobCategoryActive' }
                }
        }
    }

    return category
}

export const jobCategoryMobile = (state) =>{
    return h('select', { class:"searchJobBoxes form-control input-sm",onchange: [setCategoryChange, state], id:'categoryMobile'}, [
        h('option', { value: '', class: 'disabled' }, text('Select Category')),
        h('option', { value:'cdl'}, text('Transportation')),
        h('option', { value:'dispatcher' }, text('Distribution')),
        h('option', { value: 'administrative' }, text('Admin')),
        h('option', { value:'mechanic' }, text('Skilled Labor')),
        h('option', { value: 'other' }, text('Other'))
    ])
}