'use strict';

import { setCategory, setLocation} from '~/actions'

const transportationDict = ['cdl','driver', 'truck', 'tractor', 'regional', 'local', 'trucker', 'drive']
const distributionDict = ['dispatcher', 'dispatch', 'transportation', 'transportation supervisor', 'distribution']
const adminDict = ['administrative', 'clerical', 'office', 'administrator']
const skilledLabor = ['mechanic', 'diesel', 'ase', 'maintenance', 'service', 'technician', 'skilled labor']
const otherDict = ['other','yard', 'spotter', 'jockey']
const Dictionaries = [transportationDict, distributionDict, adminDict, skilledLabor, otherDict]



/**
 * Will set the category and/or location from a search
 * @param  state state of the page 
 * @param {string} category input for category 
 * @param {string} location input for location
 * 
 */
export const searchJobs = (state, category) =>{
    for(let dict of Dictionaries){
        if(compareToDict(category, dict)){
            setCategory(state,dict[0]);
            return{...state};
        }
    }
    setCategory(state, null);
    return {...state}
}

const compareToDict = (search, Dict) =>{
    for(let word of Dict){
        if(
                search.includes(word) ||
                word.includes(search) &&
                search !== '' 
            ){
                return true;
            }
    }
    return false;
}