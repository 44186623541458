'use strict';
import { Debounce } from "hyperapp-fx"
import { h, text } from 'hyperapp';
import { getLocationsByRadius, getCityLike } from  '../effects'
import { jobLocations } from './jobs/jobLocations'
import { changeSearchType, changeSearchTypeMobile } from '../actions'
import { cities } from './cities'

const applyNowLink = function(state){
    window.open('https://intelliapp.driverapponline.com/c/firstfleet?r=MainPage&uri_b=ia_firstfleet_479794970');
    return state;
}

const podcastLink = function(state){
    document.getElementById('podcastsNav').click();
    return state;
} 

const typingCity = (state) => [
    state,
    Debounce({
        wait:500,
        action: getCityLike
    })
]

export const banner = (state) =>{
    if(state){
        //desktop
        if (document.getElementsByName('searchType')){
            
            if (state.searchType === 'zipCodeSearch' && document.getElementById('zipCodeSearch')){
                let elem = document.getElementById('jobLocation')
                elem.checked = true;
                elem.style.display = 'block'
                document.getElementById('stateSearchSelect').style.display = 'none'
                document.getElementById('jobCity').style.display = 'none'
                document.getElementById('jobCity').value = '';
                state.cities = null;
                state.cityZip = null;

            }
            else if (document.getElementById('stateSearchSelect') && state.searchType === 'stateSearch'){
                let elem = document.getElementById('stateSearchSelect')
                document.getElementById('jobLocation').checked = false;
                elem.checked = true;
                elem.style.display = 'block'
                document.getElementById('jobLocation').style.display = 'none'
                document.getElementById('jobCity').style.display = 'none';
                document.getElementById('jobCity').value = '';
                state.cities = null;
                state.cityZip = null;
            }
            else if (document.getElementById('citySearch') && state.searchType === 'citySearch'){
                let elem = document.getElementById('jobCity');
                elem.checked = true;
                elem.style.display = 'block';
                document.getElementById('stateSearchSelect').style.display = 'none';
                document.getElementById('jobLocation').style.display = 'none';
                
            }
        }
        //mobile
        if (document.getElementsByName('searchTypeMobile')){
            if ((state.searchType === 'zipCodeSearchMobile'|| state.searchType === 'zipCodeSearch') && document.getElementById('zipCodeSearchMobile')) {
                let elem = document.getElementById('jobLocation')
                elem.checked = true;
                elem.style.display = 'block'
                document.getElementById('jobCity').style.display = 'none'
                document.getElementById('stateSearchSelect').style.display = 'none'
                document.getElementById('jobCity').value = '';

            }
            else if (document.getElementById('stateSearchMobile') && state.searchType === 'stateSearchMobile') {
                let elem = document.getElementById('stateSearchSelect')
                document.getElementById('jobLocation').checked = false;
                elem.checked = true;
                elem.style.display = 'block'
                document.getElementById('jobCity').style.display = 'none'
                document.getElementById('jobLocation').style.display = 'none'
                document.getElementById('jobCity').value = '';
            }
            else if (document.getElementById('citySearchMobile') && state.searchType === 'citySearchMobile') {
                let elem = document.getElementById('jobCity');
                elem.checked = true;
                elem.style.display = 'block';
                document.getElementById('stateSearchSelect').style.display = 'none';
                document.getElementById('jobLocation').style.display = 'none';
            }
        }
    }
    
   return h('section', {class:'section_banner parallax', id:'section_banner'},[
        h('div', {class:'banner__carousel__img'},[
            h('img', { class: 'bannerImg'})     
        ]),
        h('div', {class:'container'}, [
            h('div', {class:'row'}, [
                h('div', {class:'col-sm-6'},[

                    h('h2', { class: 'page-header text-white text-left animate-down animate-xs-step-0' }, [
                        h('b', { class: 'bannerText' }, text('Where drivers on the go choose to stay'))
                    ]),

                    h('button', { class: 'btn btn-block btn-primary applyNow-button centered col-sm-offset-3', onclick: [applyNowLink, state] }, [
                        text('Apply Now'),
                    ]),
                    h('br',{},''),

                    h('button', {class:'btn podcast_btn btn-block applyNow-button text-left centered col-sm-offset-3', onclick:[podcastLink,state] }, text('Listen to our latest podcast'))
                                      
                ]),
                h('div', { class:'col-sm-6 col-lg-5 col-lg-offset-1'}, [
                    h('div', {class:'banner__form'}, [
                        h('h2', {class:'page-header text-center'}, text('Search Jobs')),
                        h('div', {class:'form'}, [
                            h('div', { class:'btn-group btn-group-toggle col-xs-12 filterTablesDesktop', 'data-toggle':'buttons', id:'desktopBanner'}, [
                                
                                h('label', {class:'col-lg-4'}, [
                                    (state.searchType === 'zipCodeSearch' ? h('input', { type: 'radio', name: 'searchType', id: 'zipCodeSearch', onclick: [changeSearchType, state], checked: 'true' },) : h('input', { type: 'radio', name: 'searchType', id: 'zipCodeSearch', onclick: [changeSearchType, state] },)),                                    
                                    text(' Zip Code ')
                                ]),
                                
                                h('label', {class:'col-lg-4'}, [
                                    h('input', { type: 'radio', name: 'searchType', id: 'stateSearch', onclick: [changeSearchType,state]},),
                                    text(' State')

                                ]),
                                h('label', { class: 'col-lg-4' }, [
                                    h('input', { type: 'radio', name: 'searchType', id: 'citySearch', onclick: [changeSearchType, state] },),
                                    text(' City')

                                ]),
                            ]),
                            h('div', { class: ' col-xs-12 filterTablesMobile' }, [
                                h('div', {'data-toggle': 'buttons'}, [
                                    h('label', {class:'col-xs-4 mobileBanner'}, [
                                        
                                        (state.searchType === 'zipCodeSearch' ? h('input', { type: 'radio', name: 'searchTypeMobile', id: 'zipCodeSearchMobile', onclick: [changeSearchTypeMobile, state], checked: 'true' },) : h('input', { type: 'radio', name: 'searchTypeMobile', id: 'zipCodeSearchMobile', onclick: [changeSearchTypeMobile, state] },)),
                                        text(' Zip')
                                    ]),
                                    h('label', {class:'col-xs-4 mobileBanner'}, [
                                        h('input', { type: 'radio', name: 'searchTypeMobile', id: 'stateSearchMobile', onclick: [changeSearchTypeMobile, state] },),
                                        text(' State')
                                    ]),
                                    h('label', { class: 'col-xs-4 mobileBanner' }, [
                                        h('input', { type: 'radio', name: 'searchTypeMobile', id: 'citySearchMobile', onclick: [changeSearchTypeMobile, state] },),
                                        text(' City')
                                    ])
                                ]),
                                h('br',{},)
                            ]),
                            h('div', {}, [
                                h('label', { for: 'jobType' }, text('Job Location')),
                                h('input', {placeholder:'Zip Code', class: 'searchJobBoxes form-control', type: 'text', id: 'jobLocation' }),
                                
                                h('div', {}, [
                                    h('input', { placeholder: 'Murfreesboro, TN', class: 'searchJobBoxes form-control', type: 'text', id: 'jobCity', autocomplete: 'off', oninput: [typingCity, state]}),
                                    cities(state)
                                ]),
                                h('div', { id:'stateSearchSelect'},[
                                    jobLocations(state)
                                ])
                            
                            ]),
                            (state.searchType === 'zipCodeSearch' || state.searchType === 'zipCodeSearchMobile' ? h('p', {class:'text-danger'}, text('Zip must be 5 numbers long')): ''),
                            h('div', { class: '' }, [
                                h('label', { for: 'jobType' }, text('Job Type')),
                                h('input', {placeholder:'ex: Driver, Administrator, etc...', class: 'searchJobBoxes form-control', type: 'text', id: 'jobType',})
                            ]),
                            
                            h('br',{},),
                            h('button', { class: 'btn btn-block btn-primary rounded', onclick: [getLocationsByRadius, document.getElementById('jobLocation')]}, text('Search'))
                        ])
                    ])
                ])
            ])
        ])
    ])
}