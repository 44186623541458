'use strict';

import { h, text } from 'hyperapp';

export const benefits = () =>{

    return h('section', { class:'section_pricing', id:'section_benefits'},[
        h('div',{class:'container'},[
            h('div',{class:'row'},[
                h('div',{class:'col-xs-12'},[
                    h('h2',{class:'page-header text-center'},[
                        h('span',{}, text('Benefits'))
                    ]),
                    h('p', { class: 'page-subheader text-center' }, text('Furnished Uniform Program, Industry leading CSA scores Referral Bonus Program, and more'))
                ])
            ]),
            h('div',{class:'row'},[
                h('div',{class:'col-sm-4'},[
                    h('div', { class:'pricing__item benefitBoxSize', dataAnimate:'animate-up'},[
                        h('h3', { class:'pricing__item__heading highlightBenefit'},text('Work')),
                        h('ul',{class:'pricing__item__features'},[
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Quarterly bonus program ')),
                                text('for full-time drivers based on safety and fuel efficiency ')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit'}, text('Paid vacations ')),
                                text('based on length of service and available after one year of service')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit'}, text('Paid time off ')),
                                text('after two months of employment')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Holiday bonus ')),
                                text('pay program')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Weekly ')),
                                text('direct deposit')
                            ]),
                            h('li', {}, [
                                text('401k '),
                                h('span', { class: 'highlightBenefit' }, text('PLUS MATCH')),
                            ])
                        ])
                    ])
                ]),
                h('div', { class: 'col-sm-4' }, [
                    h('div', { class: 'pricing__item benefitBoxSize', dataAnimate: 'animate-up' }, [
                        h('h3', { class: 'pricing__item__heading highlightBenefit' }, text('Health')),
                        h('ul', { class: 'pricing__item__features' }, [
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Provider ')),
                                text('Blue Cross Blue Shield ')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Extensive ')),
                                text('BlueCard PPO Network')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Wellness services ')),
                                text('to maintain good health')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Prescription ')),
                                text('benefits')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Vision Service Plan ')),
                                text('(VSP)')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Dental Benefits ')),
                                text('Delta Dental')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Two ')),
                                text('unique plans')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Orthodontic benefits ')),
                                text('to dependents age 19 and under')
                            ])
                        ])
                    ])
                ]),
                h('div', { class: 'col-sm-4' }, [
                    h('div', { class: 'pricing__item benefitBoxSize', dataAnimate: 'animate-up' }, [
                        h('h3', { class: 'pricing__item__heading highlightBenefit' }, text('Life & Disability')),
                        h('ul', { class: 'pricing__item__features' }, [
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Life insurance for employee and dependents ')),
                                text('through Reliance Standard Life, hearing benefits through HearUSA and a smoking cessation program through QuitNet when enrolled in Blue Cross Blue Shield plan ')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Additional ')),
                                text('Life Insurance coverage available for employee and spouse')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Short Term ')),
                                text('Disability')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Long Term ')),
                                text('Disability')
                            ]),
                            h('li', {}, [
                                h('span', { class: 'highlightBenefit' }, text('Weekly ')),
                                text('direct deposit')
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ])
}