'use strict';

import { h, text } from 'hyperapp';
import { setLocation } from '~/actions'

const setLocationChangeMobile = (state) =>{
    
    if (document.getElementById('locationMobile').value.length) {
        document.getElementById('locationJobSection').value = document.getElementById('location').value
    }
    let elem = document.getElementById('locationMobile').value;

    document.getElementById('location').value = elem;
    setLocation(state, elem)
    return {...state};
}

const setLocationChange = (state) => {
    if (document.getElementById('location').value.length){
        document.getElementById('locationJobSection').value = document.getElementById('location').value
    }
    let elem = document.getElementById('location').value;

    document.getElementById('location').value = elem;
    setLocation(state, elem);
    return { ...state };
}

const setLocationChangeJobSection = (state) => {
    if (document.getElementById('locationJobSection').value.length) {
        document.getElementById('location').value = document.getElementById('locationJobSection').value 
    }
    let elem = document.getElementById('locationJobSection').value;

    document.getElementById('locationJobSection').value = elem;
    setLocation(state, elem);
    return { ...state };
}


export const jobLocations = (state) => {
    if (state.Jobs) {
        
        let jobLocations = []
        for (let x = 0; x < state.Jobs.length; x++) {
            let duplicate = false;
            for(let i = 0; i<jobLocations.length; i++ ){
                if (jobLocations[i].location === (state.Jobs[x].StateName)){
                    duplicate = true;
                    jobLocations[i].count = jobLocations[i].count + 1;
                }
            }
            if(!duplicate){
                jobLocations.push({
                    location: (state.Jobs[x].StateName),
                    count: 1
                })
            }
        }
        let sortedLocations = []
        for(let x = 0; x< jobLocations.length; x++){
            sortedLocations.push(jobLocations[x].location + ' (' + jobLocations[x].count + ')')
        }
        sortedLocations.sort()
        let locationElements = h('select', { class: 'form-control input-sm', onchange: [setLocationChange, state], id: 'location' }, [
            h('option', { class: 'disabled form-control', value: '' }, text('Select Location'))
        ])
        for (let location of sortedLocations) {
            let locationString = location.split('(') 
            locationElements.children.push(h('option', { value: locationString[0],}, text(location)))           
        }

        return locationElements
    }
}

export const jobLocationsJobSection = (state) => {
    if (state.Jobs) {

        let jobLocations = []
        for (let x = 0; x < state.Jobs.length; x++) {
            let duplicate = false;
            for (let i = 0; i < jobLocations.length; i++) {
                if (jobLocations[i].location === (state.Jobs[x].StateName)) {
                    duplicate = true;
                    jobLocations[i].count = jobLocations[i].count + 1;
                }
            }
            if (!duplicate) {
                jobLocations.push({
                    location: (state.Jobs[x].StateName),
                    count: 1
                })
            }
        }
        let sortedLocations = []
        for (let x = 0; x < jobLocations.length; x++) {
            sortedLocations.push(jobLocations[x].location + ' (' + jobLocations[x].count + ')')
        }
        sortedLocations.sort()
        let locationElements = h('select', { class: 'form-control input-sm', onchange: [setLocationChangeJobSection, state], id: 'locationJobSection' }, [
            h('option', { class: 'disabled form-control', value: '' }, text('Select Location'))
        ])
        for (let location of sortedLocations) {
            let locationString = location.split('(')
            locationElements.children.push(h('option', { value: locationString[0], }, text(location)))
        }

        return locationElements
    }
}

export const jobLocationMobile = (state) => {
    
    if (state.Jobs) {

        let jobLocations = []
        for (let x = 0; x < state.Jobs.length; x++) {
            let duplicate = false;
            for (let i = 0; i < jobLocations.length; i++) {
                if (jobLocations[i].location === (state.Jobs[x].StateName)) {
                    duplicate = true;
                    jobLocations[i].count = jobLocations[i].count + 1;
                }
            }
            if (!duplicate) {
                jobLocations.push({
                    location: (state.Jobs[x].StateName),
                    count: 1
                })
            }
        }
        let sortedLocations = []
        for (let x = 0; x < jobLocations.length; x++) {
            sortedLocations.push(jobLocations[x].location + ' (' + jobLocations[x].count + ')')
        }
        sortedLocations.sort()
        let locationElements = h('select', { class: 'searchJobBoxes form-control input-sm', onchange: [setLocationChangeMobile, state], id: 'locationMobile' }, [
            h('option', { class: 'disabled form-control', value: '' }, text('Select Location'))
        ])
        for (let location of sortedLocations) {
            let locationString = location.split('(')
            locationElements.children.push(h('option', { value: locationString[0], }, text(location)))
        }

        return locationElements
    }
}