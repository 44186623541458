'use strict';
import { app } from 'hyperapp';
import { main } from './view';
import { state } from './state';
import { getJobs } from './effects';

app({
    node: document.getElementById('hyperApp'),
    view: main,
    init: [
        state,
        getJobs()
        
    ]
});