'use strict';

import { h, text } from 'hyperapp';

export const values = () =>{
    return h('section', { class:'section_pricing section_alt', id:'section_values'}, [
        h('div', {class:'container'}, [
            h('div', {class: 'row'}, [
                h('div', {class:'col-xs-12'}, [
                    h('h2', {class:'page-header text-center'},[
                        h('span', {}, text('Values'))
                    ])
                ])
            ]),
            h('br',{}),
            h('div', { class: 'row' }, [
                h('div', { class: 'col-md-12' }, [
                    h('div', { class: 'col-md-6 col-xs-12' }, [
                        h('div', {class:"video-wrapper"}, [
                            <iframe src="https://player.vimeo.com/video/894164527?h=b663610528&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="FirstFleet - SERVE - Core Values"></iframe>
                        ]) 
                    ]),
                    h('div', { class: 'col-md-6 col-xs-12' }, [
                        h('div', { class: 'col-md-12' }, [
                            h('ul', { class:'value-list'}, [
                                h('li', { class: 'values-list-text'},text('Safely deliver outstanding service.')),
                                h('li', { class:'values-list-text'}, text('Empower our people to do the right thing.')),
                                h('li', { class: 'values-list-text'}, text('Relationships will be built on trust.')),
                                h('li', { class: 'values-list-text'}, text('Value, care for and respect everyone.')),
                                h('li', { class: 'values-list-text'}, text('Embrace change as a reality of progress.'))
                            ])
                        ]),
                        
                    ])
                ])
            ])
        ])
    ])
}