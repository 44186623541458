'use strict';
import { h } from 'hyperapp';
import { careers } from './components/careers'
import { navBar } from './components/navBar'
import { aboutUs } from './components/aboutUs'
import { banner } from './components/banner'
import { jobInfo } from './components/jobInfo'
import { benefits } from './components/benefits'
import { requirements } from './components/requirements'
import { questions } from './components/questions'
import { heritage } from './components/heritage'
import { responsible } from './components/responsible'
import { values } from './components/values'
import { podcasts } from'./components/podcasts'
export const main = (state) =>{
    if (location.protocol !== 'https:' && location.href !== 'http://localhost:1234/') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }

    return h('div',{}, [
        navBar(),
        banner(state),
        careers(state),
        jobInfo(state),
        aboutUs(),
        podcasts(),
        heritage(),
        responsible(),
        values(),
        benefits(),
        requirements(),
        questions()
    ])
}