'use strict';

import { h,text } from 'hyperapp'


export const requirements = () =>{

    return h('section', { id: 'section_requirements', class:'section_pricing section_alt'},[
        h('div', {class:'container'}, [
            h('div', {class:'row'}, [
                h('h2', { class:'text-center page-header highlightBenefit'}, text('Requirements'))
            ]),
            h('div', { class:'row filterTablesDesktop'}, [
                h('div', {class:'col-xs-6'}, [
                    h('div', { class: 'pricing__item requirementBoxSize', dataAnimate: 'animate-up' }, [
                        h('h3', { class: 'pricing__item__heading highlightBenefit' }, text('Minimum Requirements')),
                        h('ul', { class: 'pricing__item__features'}, [
                        h('li', {}, text('Must possess a Class A CDL from the state of residence')),
                        h('li', {}, text('Must have 1 year Tractor/Trailer Experience')),
                        h('li', {}, text('Must have no chargeable D.O.T. reportable accidents within the past three years')),
                        h('li', {}, text('Driving/Safety record must meet FirstFleet minimum requirements')),
                        h('li', {}, text('No DUI/DWI, drug or alcohol related convictions within past 5 years')),
                        h('li', {}, text('No careless or reckless driving convictions within past 5 years the applicant must have the ability to read, write, and communicate in English, and have the ability to understand the policies and regulations of FirstFleet, the Department of Transportation, and the various state and federal agencies which regulate the transportation industry')),
                        h('li', {}, text('Must submit a completed application form addressing each area of the form')),
                        h('li', {}, text('Must be physically able to perform the essential functions of the position they are applying for, with or without accommodation'))
                    ])
                    ])
                ]),
                h('div', {class:'col-xs-6'}, [
                    h('p', {}, [
                        h('b', { class:'highlightBenefit'}, text('NOTE: ')),
                        text('An accident is considered to be a DOT Recordable accident if any of the following conditions exist:'),
                        h('ul', {}, [
                            h('li', {}, text('Fatality or bodily injury requiring medical treatment away from the scene')),
                            h('li', {}, text('One or more vehicles are towed from the scene of the accident'))
                        ])
                    ]),
                    h('p', {}, [
                        h('b', { class:'highlightBenefit'}, text('NOTE: ')),
                        text('Except as mandated by FMCSA regulations, a criminal conviction will not automatically disqualify an applicant solely because the person has been convicted of a crime. We will conduct an individualized assessment and evaluate whether the conviction disqualifies the applicant. Although prior convictions or pleas may not automatically disqualify you from consideration, failure to disclose a conviction that has not been lawfully expunged will constitute falsification of your application and will disqualify you from employment.')
                    ]),
                    h('p', {}, text("All applications which meet FirstFleet's minimum hiring requirements, and for which there are positions available, will be processed and a complete background investigation will be conducted.This investigation will include verification of past and present employment, a current MVR for all states in which applicant has held a license, as well as criminal and credit reports.Any offer of employment is conditional upon the applicant successfully completing a DOT physical, physical abilities testing, a DOT drug screen and a driving test."))
                ])
            ]),
            h('div', { class:'filterTablesMobile'}, [
                h('div', { class: 'row' }, [
                    h('div', { class: 'pricing__item requirementBoxSizeMobile', dataAnimate: 'animate-up' }, [
                        h('ul', { class: 'pricing__item__features' }, [
                            h('li', {}, text('Must possess a Class A CDL from the state of residence')),
                            h('li', {}, text('Must have 1 year Tractor/Trailer Experience')),
                            h('li', {}, text('Must have no chargeable D.O.T. reportable accidents within the past three years')),
                            h('li', {}, text('Driving/Safety record must meet FirstFleet minimum requirements')),
                            h('li', {}, text('No DUI/DWI, drug or alcohol related convictions within past 5 years')),
                            h('li', {}, text('No careless or reckless driving convictions within past 5 years the applicant must have the ability to read, write, and communicate in English, and have the ability to understand the policies and regulations of FirstFleet, the Department of Transportation, and the various state and federal agencies which regulate the transportation industry')),
                            h('li', {}, text('Must submit a completed application form addressing each area of the form')),
                            h('li', {}, text('Must be physically able to perform the essential functions of the position they are applying for, with or without accommodation'))
                        ])
                    ])
                ]),
                h('br', {}, ),
                h('div', { class: 'row' }, [
                    h('p', {}, [
                        h('b', { class:'highlightBenefit'}, text('NOTE: ')),
                        text('An accident is considered to be a DOT Recordable accident if any of the following conditions exist:'),
                        h('ul', {}, [
                            h('li', {}, text('Fatality or bodily injury requiring medical treatment away from the scene')),
                            h('li', {}, text('One or more vehicles are towed from the scene of the accident'))
                        ])
                    ]),
                    h('p', {}, [
                        h('b', { class:'highlightBenefit'}, text('NOTE: ')),
                        text('Except as mandated by FMCSA regulations, a criminal conviction will not automatically disqualify an applicant solely because the person has been convicted of a crime. We will conduct an individualized assessment and evaluate whether the conviction disqualifies the applicant. Although prior convictions or pleas may not automatically disqualify you from consideration, failure to disclose a conviction that has not been lawfully expunged will constitute falsification of your application and will disqualify you from employment.')
                    ]),
                    h('p', {}, text("All applications which meet FirstFleet's minimum hiring requirements, and for which there are positions available, will be processed and a complete background investigation will be conducted.This investigation will include verification of past and present employment, a current MVR for all states in which applicant has held a license, as well as criminal and credit reports.Any offer of employment is conditional upon the applicant successfully completing a DOT physical, physical abilities testing, a DOT drug screen and a driving test."))
                ])
            ])
        ])
    ])
}