'use strict'

import { h, text } from "hyperapp";

export const podcasts = () =>
    h('section', { class: 'section_podcasts section_pricing', id:'section_podcasts'},[
        h('div', {class:'container'},[
            h('div', {class:'row'},[
                h('h2', {class:'page-header text-center'},[
                    h('span', {}, text('Podcasts'))
                ])
            ]),
            h('div', { class:'row '},[
                h('div',{class:'col-lg-6'}, [
                    h('div', { class: 'pricing__item', dataAnimate: 'animate-up' }, [
                        h('h3', { class: 'pricing__item__heading highlightBenefit' }, text('TenFour Podcast')),
                        h('ul', { class: 'pricing__item__features' }, [
                            h('li', {}, [
                                text('The TenFour Podcast is a regularly released show focusing on happenings around the company and is designed to provide for increased communiation and awareness in support of our'),
                                h('a', { href:'#section_values'}, text(' Core Values')),
                                text('.Use the play button to listen to the most recent eposide. ')
                            ])
                            
                        ])
                    ])
                ]),
                h('div',{class:'col-lg-6',},[
                    h('div',{class:'podcast_box'},[
                        h('br', {},),
                        h('p',{class:'text-center'},text('Press the play button to listen')),
                        h('iframe', {width:'100% !important',height:'180', frameborder: 'no', scrolling: 'no', seamless: '',src: 'https://share.transistor.fm/e/firstfleet-tenfour-19de701c-d546-472d-9693-2d70d5e20184/latest' })
                    ])
                    
                ])
            ])
        ])
    ])